<template>
  <div>
    <!-- dialog -->
    <van-popup v-model="bankSelect" position="bottom">
      <van-picker
        show-toolbar
        :columns="banks"
        @confirm="onConfirm"
        @cancel="bankSelect = false"
      />
    </van-popup>
    <van-dialog
      :before-close="delBankBefore"
      v-model="delBankModal"
      title="删除"
      show-cancel-button
    >
      <p style="text-align: center; color: red; padding: 15px">确认删除</p>
      <p style="text-align: center; padding: 15px; padding-top: 0px">
        {{ currentData.bank_no }} - {{ currentData.bank_name }}
      </p>
    </van-dialog>
    <van-dialog
      :before-close="editBankBefore"
      v-model="editBankModal"
      title="编辑"
      show-cancel-button
    >
      <van-form refs>
        <van-field
          v-model="currentData.bank_no"
          name="银行卡号"
          label="银行卡号"
          placeholder="银行卡号"
          disabled
          :rules="[{ required: true, message: '请填写银行卡号' }]"
        />
        <van-field
          v-model="currentData.bank_name"
          name="开户银行"
          label="开户银行"
          placeholder="开户银行"
          disabled
          :rules="[{ required: true, message: '请填写银行卡号' }]"
        />
        <van-field
          v-model="currentData.bank_branch"
          name="支行名称"
          label="支行名称"
          placeholder="支行名称"
          disabled
          :rules="[{ required: true, message: '请填写支行名称' }]"
        />
        <van-field
          v-model="currentData.bank_owner"
          name="姓名"
          label="姓名"
          disabled
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
          v-model="currentData.ds_limit"
          name="每日限额"
          label="每日限额"
          placeholder="每日限额"
          type="number"
          :rules="[{ required: true, message: '请填写每日限额' }]"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="addDouyinBankBefore"
      v-model="addDouyinModal"
      title="新增抖音红包收款账户"
      show-cancel-button
    >
      <van-form ref="addBankPForm">
        <van-field
          v-model="addBankData.bank_no"
          name="抖音号(UID)"
          label="抖音号"
          placeholder="抖音号(UID)"
          required
          :rules="[{ required: true, message: '抖音号(UID)' }]"
        />
        <van-field
          v-model="addBankData.bank_owner"
          name="抖音昵称"
          label="抖音昵称"
          placeholder="抖音昵称"
          required
          :rules="[{ required: true, message: '请填写抖音昵称' }]"
        />
        <van-field
          v-model="addBankData.ds_limit"
          name="每日限额"
          label="每日限额"
          required
          placeholder="每日限额"
          type="number"
          :rules="[{ required: true, message: '请填写每日限额' }]"
        />
        <van-field required name="uploader" label="抖音号二维码">
          <template #input>
            <van-uploader
              :before-read="beforeUpload"
              multiple
              accept="image/*,video/*"
              v-model="files"
              :max-count="6"
            />
          </template>
        </van-field>
        <van-field
          required
          v-model="addBankData.oneCode"
          name="谷歌验证码"
          label="谷歌验证码"
          placeholder="谷歌验证码"
          v-if="$store.state.selfData.enable_2fa == 1"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="addDigitalyBefore"
      v-model="addDigitalyModal"
      title="新增数字人民币收款账户"
      show-cancel-button
    >
      <van-form ref="addBankPForm">
        <van-field
          v-model="addBankData.bank_no"
          name="钱包编号"
          label="钱包编号"
          placeholder="钱包编号"
          required
          :rules="[{ required: true, message: '请填写钱包编号' }]"
        />
        <van-field
          v-model="addBankData.bank_owner"
          name="姓名"
          label="姓名"
          placeholder="姓名"
          required
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
          v-model="addBankData.ds_limit"
          name="每日限额"
          label="每日限额"
          required
          placeholder="每日限额"
          type="number"
          :rules="[{ required: true, message: '请填写每日限额' }]"
        />
        <van-field
          required
          v-model="addBankData.oneCode"
          name="谷歌验证码"
          label="谷歌验证码"
          placeholder="谷歌验证码"
          v-if="$store.state.selfData.enable_2fa == 1"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="addAlipayBankBefore"
      v-model="addAlipayModal"
      title="新增支付宝收款账户"
      show-cancel-button
    >
      <van-form ref="addBankPForm">
        <van-field
          v-model="addBankData.bank_no"
          name="收款账号"
          label="收款账号"
          placeholder="收款账号"
          required
          :rules="[{ required: true, message: '请填写账号' }]"
        />
        <van-field
          v-model="addBankData.bank_owner"
          name="昵称"
          label="昵称"
          placeholder="昵称"
          required
          :rules="[{ required: true, message: '请填写昵称' }]"
        />
        <van-field
          v-model="addBankData.bank_branch"
          name="真实姓名"
          label="真实姓名"
          placeholder="真实姓名"
          required
          :rules="[{ required: true, message: '请填写真实姓名' }]"
        />
        <van-field
          v-model="addBankData.ds_limit"
          name="每日限额"
          label="每日限额"
          required
          placeholder="每日限额"
          type="number"
          :rules="[{ required: true, message: '请填写每日限额' }]"
        />

        <van-field
          v-if="showAliLogin()"
          v-model="addBankData.alipay_userid"
          name="支付宝UID"
          label="支付宝UID"
          placeholder="支付宝UID"
          @input="
            addBankData.alipay_userid = addBankData.alipay_userid.replace(
              /\s+/g,
              ''
            )
          "
          type="number"
          :rules="[{ required: true, message: '请填写支付宝UID' }]"
        />
        <van-field label="获取支付宝UID" v-if="showAliLogin()">
          <template #input>
            <!-- <van-button
              size="normal"
              v-if="isMobile()"
              color="linear-gradient(to right, #ff6034, #ee0a24)"
              style="height: 35px"
              @click="getUID"
            >
              点击获取UID
            </van-button> -->

            <vue-qrcode
              margin="0"
              width="150"
              value="alipays://platformapi/startapp?appId=20000067&url=https%3A%2F%2Frender.alipay.com%2Fp%2Ff%2Ffd-ixpo7iia%2Findex.html"
            />
          </template>
        </van-field>

        <van-field required name="uploader" label="二维码">
          <template #input>
            <van-uploader accept="image/*" v-model="files" :max-count="1" />
          </template>
        </van-field>
        <!-- <van-field required label="" v-if="showAliLogin()">
          <template #input>
            <van-button
              size="normal"
              color="linear-gradient(to right, #ff6034, #ee0a24)"
              @click="loginAlipay"
              style="height: 35px"
              v-if="!addBankData.alipay_userid"
            >
              登入支付宝
            </van-button>
            <van-button
              size="normal"
              color="linear-gradient(to right, #ff6034, #ee0a24)"
              @click="clearnAlipay"
              style="height: 60px"
              v-else
            >
              取消登入支付宝
            </van-button>
          </template>
        </van-field> -->
        <van-field
          required
          v-model="addBankData.oneCode"
          name="谷歌验证码"
          label="谷歌验证码"
          placeholder="谷歌验证码"
          v-if="$store.state.selfData.enable_2fa == 1"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="addBankBefore"
      v-model="addBankModal"
      title="新增账户"
      show-cancel-button
    >
      <van-form ref="addBankPForm">
        <multiselect
          v-model="addBankData.bank_name"
          placeholder="选择开户银行"
          :options="banks"
          tagPlaceholder=""
          selectLabel=""
          deselectLabel=""
        >
          <span slot="noResult"> 查无银行 </span>
        </multiselect>
        <van-field
          v-if="addBankData.bank_name != '微信'"
          v-model="addBankData.bank_no"
          :name="returnName(addBankData.bank_name)"
          :label="returnName(addBankData.bank_name)"
          :placeholder="returnName(addBankData.bank_name)"
          required
          :rules="[
            {
              required: true,
            },
          ]"
        />
        <!-- 支付宝 -->
        <van-field
          v-if="addBankData.bank_name != '数字人民币'"
          v-model="addBankData.bank_branch"
          :name="
            addBankData.bank_name == '支付宝' || addBankData.bank_name == '微信'
              ? '真实姓名'
              : '支行名称'
          "
          :label="
            addBankData.bank_name == '支付宝' || addBankData.bank_name == '微信'
              ? '真实姓名'
              : '支行名称'
          "
          :placeholder="
            addBankData.bank_name == '支付宝' || addBankData.bank_name == '微信'
              ? '真实姓名'
              : '支行名称'
          "
          required
          :rules="[
            {
              required: true,
            },
          ]"
        />
        <van-field
          v-model="addBankData.bank_owner"
          :name="
            addBankData.bank_name == '支付宝' || addBankData.bank_name == '微信'
              ? '昵称'
              : '姓名'
          "
          :label="
            addBankData.bank_name == '支付宝' || addBankData.bank_name == '微信'
              ? '昵称'
              : '姓名'
          "
          :placeholder="
            addBankData.bank_name == '支付宝' || addBankData.bank_name == '微信'
              ? '昵称'
              : '姓名'
          "
          required
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="addBankData.ds_limit"
          name="每日限额"
          label="每日限额"
          required
          placeholder="每日限额"
          type="number"
          :rules="[{ required: true }]"
        />
        <van-field
          v-if="showAliLogin()"
          v-model="addBankData.alipay_userid"
          name="支付宝UID"
          label="支付宝UID"
          placeholder="支付宝UID"
          @input="
            addBankData.alipay_userid = addBankData.alipay_userid.replace(
              /\s+/g,
              ''
            )
          "
          type="number"
          :rules="[{ required: true, message: '请填写支付宝UID' }]"
        />

        <van-field label="获取支付宝UID" v-if="showAliLogin()">
          <template #input>
            <!-- <van-button
              size="normal"
              v-if="isMobile()"
              color="linear-gradient(to right, #ff6034, #ee0a24)"
              style="height: 35px"
              @click="getUID"
            >
              点击获取UID
            </van-button> -->

            <vue-qrcode
              margin="0"
              width="150"
              value="alipays://platformapi/startapp?appId=20000067&url=https%3A%2F%2Frender.alipay.com%2Fp%2Ff%2Ffd-ixpo7iia%2Findex.html"
            />
          </template>
        </van-field>

        <van-field
          v-if="addBankData.bank_name"
          name="uploader"
          :label="
            addBankData.bank_name == '支付宝' || addBankData.bank_name == '微信'
              ? '二维码'
              : '文件上传'
          "
          :required="
            addBankData.bank_name == '支付宝' || addBankData.bank_name == '微信'
          "
        >
          <template #input>
            <!-- beforeUpload -->
            <canvas id="canvas" style="display: none"></canvas>
            <van-uploader
              @delete="
                addBankData.bank_name == '微信'
                  ? (addBankData.bank_no = '')
                  : ''
              "
              :before-read="
                addBankData.bank_name == '微信' ||
                addBankData.bank_name == '支付宝'
                  ? beforeUploadAli
                  : beforeUpload
              "
              :multiple="
                !(
                  addBankData.bank_name == '微信' ||
                  addBankData.bank_name == '支付宝'
                )
              "
              accept="image/*,video/*"
              v-model="files"
              :max-count="
                addBankData.bank_name == '微信' ||
                addBankData.bank_name == '支付宝'
                  ? 1
                  : 6
              "
            />
          </template>
        </van-field>
        <van-field
          required
          v-model="addBankData.oneCode"
          name="谷歌验证码"
          label="谷歌验证码"
          placeholder="谷歌验证码"
          v-if="$store.state.selfData.enable_2fa == 1"
        />
      </van-form>
    </van-dialog>
    <!-- dialog -->
    <!-- top -->
    <van-sticky>
      <van-nav-bar @click-left="onClickLeft" title="收款账户" left-arrow>
        <template #left>
          <van-icon name="arrow-left" />
        </template>
        <template #right v-if="$store.state.selfData.is_alipay == 0">
          <div
            :style="{ color: $returnColor().color3 }"
            class="addAcc"
            @click="addBank"
          >
            <van-icon class="plus" name="plus" />新增
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div class="wrap">
      <!-- :style="{
        backgroundImage:
          'url(' +
          $returnColor().path +
          ($store.state.selfData.member_id
            ? '/home-page_bg.png'
            : '/home-page_bg.png') +
          ')',
      }" -->
      <div
        v-if="$store.state.selfData.is_alipay == 4"
        style="
          display: flex;
          margin-bottom: 10px;
          justify-content: flex-start;
          width: 355px;
        "
      >
        <van-button
          size="normal"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          @click="addDigital"
        >
          新增收款-数字人民币
        </van-button>
        <van-button
          size="normal"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          style="margin-left: 5px"
          @click="addPay"
        >
          新增付款-银行卡
        </van-button>
      </div>
      <div
        v-if="$store.state.selfData.is_alipay == 1"
        style="
          display: flex;
          margin-bottom: 10px;
          justify-content: flex-start;
          width: 355px;
        "
      >
        <van-button
          size="normal"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          @click="addAlipay"
        >
          新增收款-支付宝
        </van-button>
        <van-button
          size="normal"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          style="margin-left: 5px"
          @click="addPay"
        >
          新增付款-银行卡
        </van-button>
      </div>
      <div
        v-if="$store.state.selfData.is_alipay == 2"
        style="
          display: flex;
          margin-bottom: 10px;
          justify-content: flex-start;
          width: 355px;
        "
      >
        <van-button
          size="normal"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          @click="addDouyin"
        >
          新增收款-抖音红包
        </van-button>
        <van-button
          size="normal"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          style="margin-left: 5px"
          @click="addPay"
        >
          新增付款-银行卡
        </van-button>
      </div>
      <div class="tableWrap" v-for="list in bankList" :key="list.id">
        <p class="tableContent">
          <span class="tableTitle">
            {{ returnBankNameTitle(list.bank_name) }}
          </span>
          <span class="tablesContent">
            {{ list.bank_no }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 开户银行 </span>
          <span class="tablesContent">
            {{ list.bank_name }}
          </span>
        </p>
        <p
          class="tableContent"
          v-if="list.bank_name != '抖音红包' && list.bank_name != '数字人民币'"
        >
          <span class="tableTitle">
            {{
              list.bank_name != "支付宝" && list.bank_name != "微信"
                ? "支行名称"
                : "真实姓名"
            }}
          </span>
          <span class="tablesContent">
            {{ list.bank_branch }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle">
            {{
              list.bank_name != "支付宝" && list.bank_name != "微信"
                ? "姓名"
                : "昵称"
            }}
          </span>
          <span class="tablesContent">
            {{ list.bank_owner }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 异常 </span>
          <span class="tablesContent">
            {{ list.aberrant == 0 ? "正常" : "异常" }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 每日限额 </span>
          <span class="tablesContent">
            {{ list.ds_limit || "未设置" }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 今日收款 </span>
          <span class="tablesContent">
            {{ list.trans_amount }}
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 启用状态 </span>
          <span class="tablesContent">
            <van-switch
              v-if="list.enable != 2"
              size="24"
              :active-color="$returnColor().color3"
              inactive-color="#cccccc"
              :value="list.enable == 1"
              @input="changeEnable(list)"
            />
            <span v-else style="color: red">待审核</span>
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 维护时段 </span>
          <span
            class="tablesContent"
            v-if="list.bank && list.bank.start && list.bank.end"
          >
            {{ list.bank.start }} ~ {{ list.bank.end }}
          </span>
          <span class="tablesContent" v-else> 无 </span>
        </p>

        <p class="tableContent">
          <span class="tableTitle"> 收款卡 </span>
          <span class="tablesContent">
            <van-switch
              size="24"
              :active-color="$returnColor().color3"
              inactive-color="#cccccc"
              :value="list.is_collection == 1"
              @input="changeIsC(list)"
            />
          </span>
        </p>
        <p class="tableContent">
          <span class="tableTitle"> 出款卡 </span>
          <span class="tablesContent">
            <van-switch
              size="24"
              :active-color="$returnColor().color3"
              inactive-color="#cccccc"
              :value="list.is_pay == 1"
              @input="changeIspay(list)"
            />
          </span>
        </p>
        <p
          class="tableContent"
          v-if="
            ($store.state.selfData.m_id == 60007 ||
              $store.state.selfData.m_id == 60002) &&
            list.bank_name == '支付宝'
          "
        >
          <span class="tableTitle"> 绑定 </span>
          <span class="tablesContent">
            {{ list.alipay_userid ? "已绑定" : "未绑定" }}
          </span>
        </p>
        <div class="editBtn">
          <p
            @click="editBank(list)"
            :style="{
              color: $returnColor().color3,
              width: $store.state.selfData.m_id === 60007 ? '100%' : '50%',
            }"
          >
            编辑银行卡
          </p>
          <p
            style="color: red"
            v-if="$store.state.selfData.m_id != 60007"
            @click="delBank(list)"
          >
            删除银行卡
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "vant";
import Multiselect from "vue-multiselect";
import QrCode from "qrcode-reader";
import * as imageConversion from "image-conversion";
import VueQrcode from "vue-qrcode";
import jsQR from "jsqr";

export default {
  components: { Multiselect, VueQrcode },
  data() {
    return {
      bankSelect: false,
      show: true,
      newPassword: "",
      password: "",
      paginate: {},
      bankList: [],
      addBankModal: false,
      editBankModal: false,
      addBankData: { type: 0, bank_no: "" },
      currentPage: 1,
      currentData: {},
      banks: [],
      files: [],
      delBankModal: false,
      open: false,
      next_page: true,
      wait: false,
      addAlipayModal: false,
      addDouyinModal: false,
      addDigitalyModal: false,
      getCT: "",
      currentAliBankNo: "",
      currentAliBankB: "",
      isAliQrcode: false,
    };
  },
  watch: {
    // currentPage() {
    // this.getBank();
    // },
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    getUID() {
      window.open(
        `alipays://platformapi/startapp?appId=20000067&url=https%3A%2F%2Frender.alipay.com%2Fp%2Ff%2Ffd-ixpo7iia%2Findex.html`
      );
    },
    getAliCookie() {
      this.addBankData = {
        ...this.addBankData,
        alipay_userid: this.$cookie.get("user_id"),
      };
      this.currentAliBankNo = this.$cookie.get("user_name");
      this.currentAliBankB = this.$cookie.get("mobile");
    },
    clearnAlipay() {
      this.$cookie.delete("user_id");
      this.$cookie.delete("user_name");
      this.$cookie.delete("mobile");
      delete this.addBankData.alipay_userid;
    },
    isLoginAl() {
      if (this.$cookie.get("user_id")) return true;
      return false;
    },
    showAliLogin() {
      return (
        this.addBankData.bank_name &&
        this.addBankData.bank_name == "支付宝" &&
        (this.$store.state.selfData.m_id == 60007 ||
          this.$store.state.selfData.m_id == 60002)
      );
    },
    loginAlipay() {
      let vm = this;
      try {
        let llog = window.open(
          `https://alipaylogin-750237670685.asia-east2.run.app/start-monitor?parentOrigin=${location.origin}`,
          "_blank",
          "width=800,height=600"
        );
        window.addEventListener("message", function (event) {
          if (
            event.origin ===
            "https://alipaylogin-750237670685.asia-east2.run.app"
          ) {
            console.log("Received data from new window: ", event.data);
            if (event.data.data.outUserNo) {
              vm.$cookie.set("user_id", event.data.data.outUserNo);
            } else {
              vm.$cookie.set("user_id", event.data.data.userId);
              vm.$cookie.set("user_name", event.data.data.userName);
              vm.$cookie.set("mobile", event.data.data.mobile);
            }
            llog.close();
          }
        });
      } catch (e) {
        console.error("Unable to access the window: ", e);
      }
    },
    // 数字人民币
    returnName(name) {
      if (name == "支付宝" || name == "微信") {
        return "收款账号";
      }
      if (name == "数字人民币") {
        return "钱包编号";
      }
      return "银行卡号";
    },
    async chechWechatColor(imageFile) {
      return new Promise((resolve) => {
        const vm = this;
        const file = imageFile;
        let check = true;
        if (file && file.type.startsWith("image")) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const img = new Image();
            img.onload = function () {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);

              const points = [
                { x: 0, y: 0 },
                { x: img.width - 1, y: 0 },
              ];
              for (const point of points) {
                const imageData = ctx.getImageData(point.x, point.y, 1, 1);
                const pixel = imageData.data;
                if (
                  !(
                    (pixel[0] >= -20 &&
                      pixel[0] <= 30 &&
                      pixel[1] >= 168 &&
                      pixel[1] <= 218 &&
                      pixel[2] >= 71 &&
                      pixel[2] <= 121) ||
                    (pixel[0] >= 222 &&
                      pixel[0] <= 262 &&
                      pixel[1] >= 235 &&
                      pixel[1] <= 275 &&
                      pixel[2] >= 221 &&
                      pixel[2] <= 261 &&
                      pixel[3] >= 235 &&
                      pixel[3] <= 275) ||
                    (pixel[0] >= -13 &&
                      pixel[0] <= 27 &&
                      pixel[1] >= -20 &&
                      pixel[1] <= 20 &&
                      pixel[2] >= -20 &&
                      pixel[2] <= 20 &&
                      pixel[3] >= 235 &&
                      pixel[3] <= 275)
                  )
                ) {
                  vm.$toast.fail("请选择正确二维码(1)");
                  resolve(false);
                  return;
                }
              }

              if (check) {
                resolve(true);
              }
            };
            img.src = e.target.result;
            img.crossOrigin = "Anonymous";
          };
          reader.readAsDataURL(file);
        }
      });
    },
    async decodeQRCode2(imageFile) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const imageData = event.target.result;
          const qr = new QrCode();
          qr.callback = (err, value) => {
            if (err) {
              this.$toast.fail("请选择正确二维码");
              reject(err);
              return;
            }
            resolve(value.result);
          };
          qr.decode(imageData);
        };
        reader.readAsDataURL(imageFile);
      });
    },
    async decodeQRCode(imageFile) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
          const imageData = ctx.getImageData(0, 0, img.width, img.height);

          const result = jsQR(
            imageData.data,
            imageData.width,
            imageData.height
          );
          if (result) {
            resolve(result.data);
          } else {
            resolve(false);
          }
        };

        img.onerror = (error) => {
          reject(error);
        };
      });
    },
    async beforeUploadAli(imageFile) {
      if (this.$store.state.selfData.m_id == 82926) {
        return imageFile;
      }
      let check = true;
      if (
        this.addBankData.bank_name == "微信" &&
        (this.$store.state.selfData.m_id == 60007 ||
          this.$store.state.selfData.m_id == 60002)
      ) {
        const result = await this.chechWechatColor(imageFile);
        check = result;
        check = true;
      }
      if (!check) {
        setTimeout(() => {
          this.files = [];
        }, 500);
        return false;
      }
      let qrcodeContent = await this.decodeQRCode(imageFile);
      if (!qrcodeContent) qrcodeContent = await this.decodeQRCode2(imageFile);
      return new Promise((resolve, reject) => {
        if (!qrcodeContent) {
          this.$toast.fail("请选择正确二维码(3)");
          reject();
          setTimeout(() => {
            this.files = [];
          }, 500);
        }
        if (this.addBankData.bank_name == "微信") {
          if (
            qrcodeContent.indexOf("wxp://") == 0 ||
            qrcodeContent.indexOf("payapp") != -1 ||
            qrcodeContent.indexOf("xyhangye") != -1 ||
            qrcodeContent.indexOf("yiyun") != -1 ||
            qrcodeContent.indexOf("business.yiyun") != -1 ||
            qrcodeContent.indexOf("xm.meng52") != -1 ||
            qrcodeContent.indexOf("q.lakala") != -1 ||
            qrcodeContent.indexOf("pay.huisaoxinxi") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("p.anxiaostore") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("wechatpay") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("mf.jlpay") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("eycard.cn") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("mchback") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("yhuser") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("alipay.com") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("lililex") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("mq.hkrt") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("fp.aipgd") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("q.jxnxs") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("eqishou") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("jfyh5") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("syb") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("epay") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("jftpay") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("qr.95516") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("lhsd.") != -1
          ) {
            this.addBankData.bank_no = qrcodeContent;
            resolve(imageFile);
          } else {
            this.$toast.fail("请选择正确二维码(2)");
            reject();
          }
        }
        if (this.addBankData.bank_name == "支付宝") {
          if (qrcodeContent.toLocaleLowerCase().indexOf("alipay.com") != -1) {
            resolve(imageFile);
            this.isAliQrcode = true;
          } else {
            this.isAliQrcode = false;
          }
          if (
            qrcodeContent
              .toLocaleLowerCase()
              .indexOf("https://qr.alipay.com") == 0 ||
            qrcodeContent
              .toLocaleLowerCase()
              .indexOf("https://cloudpay.maycpay.com") == 0 ||
            qrcodeContent.toLocaleLowerCase().indexOf("xpay") != -1 ||
            qrcodeContent.indexOf("yiyun") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("zkeduo") != -1 ||
            qrcodeContent.indexOf("xyhangye") != -1 ||
            qrcodeContent.indexOf("xm.meng52") != -1 ||
            qrcodeContent.indexOf("q.lakala") != -1 ||
            qrcodeContent.indexOf("payapp") != -1 ||
            qrcodeContent.indexOf("business.yiyun") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("p.anxiaostore") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("mf.jlpay") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("lhsd.") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("eycard.cn") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("mchback") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("yhuser") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("alipay.com") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("lililex") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("fp.aipgd") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("q.jxnxs") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("mq.hkrt") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("jfyh5") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("eqishou") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("syb") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("jftpay") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("epay") != -1 ||
            qrcodeContent.toLocaleLowerCase().indexOf("qr.95516") != -1 ||
            this.$store.state.selfData.m_id != 60007
          ) {
            resolve(imageFile);
          } else {
            this.$toast.fail("请选择正确二维码(2)");
            reject();
          }
        }
      });
    },
    beforeUpload(files) {
      this.$store.dispatch("setLoadding", true);
      let allFile = [];
      if (!Array.isArray(files)) {
        allFile = [files];
      } else {
        allFile = files;
      }
      return Promise.all(
        allFile.map((file) => {
          if (file.type.indexOf("video") != -1) {
            this.$store.dispatch("setLoadding", false);
            return file;
          }
          return new Promise((resolve, reject) => {
            imageConversion
              .compressAccurately(file, 1000)
              .then((res) => {
                resolve(res);
                this.$store.dispatch("setLoadding", false);
              })
              .catch((err) => {
                this.$toast.fail("请检查文件格式");
                this.$store.dispatch("setLoadding", false);
                reject(err);
              });
          });
        })
      );
    },
    returnBankNameTitle(name) {
      if (name == "支付宝") {
        return "支付宝账号";
      }
      if (name == "抖音红包") {
        return "抖音号";
      }
      if (name == "数字人民币") {
        return "钱包编号";
      }
      return "银行卡号";
    },
    addDouyin() {
      this.files = [];
      this.addDouyinModal = true;
      this.addBankData = { type: 0, bank_name: "抖音红包", is_pay: 0 };
    },
    addDigital() {
      this.files = [];
      this.addDigitalyModal = true;
      this.addBankData = { type: 0, bank_name: "数字人民币", is_pay: 0 };
    },
    addAlipay() {
      this.addAlipayModal = true;
      this.clearnAlipay();
      this.files = [];
      this.addBankData = { type: 0, bank_name: "支付宝", is_pay: 0 };
    },
    addPay() {
      this.addBankData = { type: 0, is_pay: 1 };
      this.addBankModal = true;
    },
    addBank() {
      this.files = [];
      this.clearnAlipay();
      if (this.$store.state.selfData.is_alipay) {
        this.addAlipayModal = true;
        this.addBankData = { type: 0, bank_name: "支付宝" };
      } else {
        this.addBankModal = true;
        this.addBankData = { type: 0 };
      }
    },
    delBank(list) {
      this.currentData = JSON.parse(JSON.stringify(list));
      this.delBankModal = true;
    },
    onConfirm(value) {
      this.addBankData.bank_name = value;
      this.bankSelect = false;
    },
    editBank(list) {
      this.currentData = JSON.parse(JSON.stringify(list));
      this.editBankModal = true;
    },
    changeIsC(list) {
      let currentData = JSON.parse(JSON.stringify(list));
      if (currentData.enable == 2) delete currentData.enable;
      this.$dialog
        .confirm({
          title: "提醒",
          message: `是否${list.is_collection == "1" ? "关闭" : "开启"}收款卡？`,
        })
        .then(() => {
          currentData.is_collection = list.is_collection == "1" ? 0 : 1;
          this.$http
            .put(`/mobile/bankCard/${list.id}`, {
              is_collection: currentData.is_collection,
              bank_no: currentData.bank_no,
            })
            .then((res) => {
              if (res.data.code == 0) {
                // this.getBank();
                list.is_collection == "1"
                  ? (list.is_collection = 0)
                  : (list.is_collection = 1);
              }
            });
        });
    },
    changeIspay(list) {
      let currentData = JSON.parse(JSON.stringify(list));
      if (currentData.enable == 2) delete currentData.enable;
      this.$dialog
        .confirm({
          title: "提醒",
          message: `是否${list.is_pay == "1" ? "关闭" : "开启"}出款卡？`,
        })
        .then(() => {
          currentData.is_pay = list.is_pay == "1" ? 0 : 1;
          this.$http
            .put(`/mobile/bankCard/${list.id}`, {
              is_pay: currentData.is_pay,
              bank_no: currentData.bank_no,
            })
            .then((res) => {
              if (res.data.code == 0) {
                // this.getBank();
                list.is_pay == "1" ? (list.is_pay = 0) : (list.is_pay = 1);
              }
            });
        });
    },
    changeEnable(list) {
      let currentData = JSON.parse(JSON.stringify(list));
      this.$dialog
        .confirm({
          title: "提醒",
          message: `是否${list.enable == "1" ? "关闭" : "开启"}账户？`,
        })
        .then(() => {
          currentData.enable = list.enable == "1" ? 0 : 1;
          this.$http
            .put(`/mobile/bankCard/${list.id}`, {
              enable: currentData.enable,
              bank_no: currentData.bank_no,
            })
            .then((res) => {
              if (res.data.code == 0) {
                list.enable == "1" ? (list.enable = 0) : (list.enable = 1);
              }
            });
        });
    },
    getBank() {
      this.$http
        .get(`/mobile/bankCard?type=0&page=${this.currentPage}&paginate=1`)
        .then((res) => {
          if (res.data.code == 0) {
            this.wait = false;
            this.next_page = res.data.next_page;
            let listData = JSON.parse(JSON.stringify(this.bankList));
            if (this.currentPage == 1) {
              listData = [];
            }
            res.data.data.forEach((data) => {
              let have = false;
              listData.forEach((e) => {
                if (e.id == data.id) {
                  have = true;
                }
              });
              if (!have) {
                listData.push(data);
              }
            });
            this.bankList = listData;
          }
        });
    },
    delBankBefore(method, done) {
      if (method == "confirm") {
        this.$http
          .delete(`/mobile/bankCard/${this.currentData.id}`)
          .then((res) => {
            if (res.data.code == 0) {
              this.getBank();
              this.delBankModal = false;
              Toast.success("成功");
            }
          });
      } else {
        done();
      }
      done();
    },
    async addDouyinBankBefore(method, done) {
      let vm = this;
      if (method == "confirm") {
        try {
          this.addBankData.bank_no = this.addBankData.bank_no.trim();
        } catch {
          console.log("err");
        }
        if (!this.addBankData.bank_no) {
          Toast.fail("请输入银行卡号");
          done(false);
          return;
        }
        // if (!this.addBankData.bank_branch) {
        //   Toast.fail("请输入支行名称");
        //   done(false);
        //   return;
        // }
        if (!this.addBankData.bank_name) {
          Toast.fail("请输入开户银行");
          done(false);
          return;
        }
        if (!this.addBankData.bank_owner) {
          Toast.fail("请输入姓名");
          done(false);
          return;
        }
        if (!this.addBankData.ds_limit) {
          Toast.fail("请输入每日限额");
          done(false);
          return;
        }
        // let reg =
        //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        // if (!reg.test(this.addBankData.bank_no)) {
        //   Toast.fail("卡号格式错误");
        //   done(false);
        //   return;
        // }
        var formData = new FormData();
        Object.keys(this.addBankData).forEach((e) => {
          formData.append(e, this.addBankData[e]);
        });
        await this.files.forEach((e, index) => {
          this.$store.dispatch("setLoadding", true);
          formData.append("files[]", e.file);
          if (index == this.files.length - 1) {
            this.$http
              .post(`/mobile/bankCard`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((res) => {
                if (res.data.code == 0) {
                  vm.addDouyinModal = false;
                  vm.addAlipayModal = false;
                  vm.getBank();
                  Toast.success("成功");
                } else {
                  alert(res.data.message);
                }
              });
          }
          // compression(e.file, index == this.files.length - 1);
        });
        if (!this.files.length) {
          this.$http
            .post(`/mobile/bankCard`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              if (res.data.code == 0) {
                vm.addDouyinModal = false;
                vm.getBank();
                Toast.success("成功");
              } else {
                alert(res.data.message);
              }
            });
        }
        done(false);
      } else {
        done();
        return;
      }
    },
    async addDigitalyBefore(method, done) {
      let vm = this;
      if (method == "confirm") {
        try {
          this.addBankData.bank_no = this.addBankData.bank_no.trim();
        } catch {
          console.log("err");
        }
        if (!this.addBankData.bank_no) {
          Toast.fail("请输入银行卡号");
          done(false);
          return;
        }
        if (!this.addBankData.bank_owner) {
          Toast.fail("请输入姓名");
          done(false);
          return;
        }
        if (!this.addBankData.ds_limit) {
          Toast.fail("请输入每日限额");
          done(false);
          return;
        }

        var formData = new FormData();
        Object.keys(this.addBankData).forEach((e) => {
          formData.append(e, this.addBankData[e]);
        });
        await this.files.forEach((e, index) => {
          this.$store.dispatch("setLoadding", true);
          formData.append("files[]", e.file);
          if (index == this.files.length - 1) {
            this.$http
              .post(`/mobile/bankCard`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((res) => {
                if (res.data.code == 0) {
                  vm.addBankModal = false;
                  vm.addDigitalyModal = false;
                  vm.getBank();
                  Toast.success("成功");
                } else {
                  alert(res.data.message);
                }
              });
          }
        });
        if (!this.files.length) {
          this.$http
            .post(`/mobile/bankCard`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              if (res.data.code == 0) {
                vm.addDigitalyModal = false;
                vm.getBank();
                Toast.success("成功");
              } else {
                alert(res.data.message);
              }
            });
        }
        done(false);
      } else {
        done();
        return;
      }
    },
    async addAlipayBankBefore(method, done) {
      let vm = this;
      if (method == "confirm") {
        try {
          this.addBankData.bank_no = this.addBankData.bank_no.trim();
        } catch {
          console.log("err");
        }
        if (!this.addBankData.bank_no) {
          Toast.fail("请输入银行卡号");
          done(false);
          return;
        }
        if (!this.addBankData.bank_branch) {
          Toast.fail("请输入真实姓名");
          done(false);
          return;
        }
        if (!this.addBankData.bank_name) {
          Toast.fail("请输入开户银行");
          done(false);
          return;
        }
        if (!this.addBankData.bank_owner) {
          Toast.fail("请输入昵称");
          done(false);
          return;
        }
        if (!this.addBankData.ds_limit) {
          Toast.fail("请输入每日限额");
          done(false);
          return;
        }

        if (
          this.showAliLogin() &&
          this.addBankData.bank_name.indexOf("支付宝") != -1 &&
          !this.isAliQrcode &&
          !this.addBankData.alipay_userid
        ) {
          done(false);
          Toast.fail("请输入支付宝UID");
          return;
        } else {
          if (this.addBankData.alipay_userid) {
            this.addBankData.alipay_userid =
              this.addBankData.alipay_userid.replace(/\s+/g, "");
          }
        }

        var formData = new FormData();
        Object.keys(this.addBankData).forEach((e) => {
          formData.append(e, this.addBankData[e]);
        });
        await this.files.forEach((e, index) => {
          this.$store.dispatch("setLoadding", true);
          formData.append("files[]", e.file);
          if (index == this.files.length - 1) {
            this.$http
              .post(`/mobile/bankCard`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((res) => {
                if (res.data.code == 0) {
                  vm.addBankModal = false;
                  vm.addAlipayModal = false;
                  vm.getBank();
                  Toast.success("成功");
                } else {
                  alert(res.data.message);
                }
              });
          }
          // compression(e.file, index == this.files.length - 1);
        });
        if (!this.files.length) {
          this.$http
            .post(`/mobile/bankCard`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              if (res.data.code == 0) {
                vm.addAlipayModal = false;
                vm.getBank();
                Toast.success("成功");
              } else {
                alert(res.data.message);
              }
            });
        }
        done(false);
      } else {
        done();
        return;
      }
    },
    compareWithWildcard(bank_no, mobile) {
      const regex = new RegExp(mobile.replace(/\*/g, ".*"));
      return regex.test(bank_no);
    },
    async addBankBefore(method, done) {
      let vm = this;
      if (method == "confirm") {
        try {
          this.addBankData.bank_no = this.addBankData.bank_no.trim();
        } catch {
          console.log("err");
        }
        if (!this.addBankData.bank_no) {
          Toast.fail("请输入银行卡号");
          done(false);
          return;
        }
        if (
          this.showAliLogin() &&
          this.addBankData.bank_name.indexOf("支付宝") != -1 &&
          !this.isAliQrcode &&
          !this.addBankData.alipay_userid
        ) {
          done(false);
          Toast.fail("请输入支付宝UID");
          return;
        } else {
          if (this.addBankData.alipay_userid) {
            this.addBankData.alipay_userid =
              this.addBankData.alipay_userid.replace(/\s+/g, "");
          }
        }
        if (
          !this.addBankData.bank_branch &&
          this.addBankData.bank_name != "数字人民币" &&
          this.addBankData.bank_name != "微信"
        ) {
          Toast.fail("请输入支行名称");
          done(false);
          return;
        }
        if (!this.addBankData.bank_name) {
          Toast.fail("请输入开户银行");
          done(false);
          return;
        }
        if (!this.addBankData.bank_owner) {
          Toast.fail("请输入姓名");
          done(false);
          return;
        }
        if (!this.addBankData.ds_limit) {
          Toast.fail("请输入每日限额");
          done(false);
          return;
        }

        if (!this.addBankData.alipay_userid)
          delete this.addBankData.alipay_userid;

        var formData = new FormData();

        Object.keys(this.addBankData).forEach((e) => {
          formData.append(e, this.addBankData[e]);
        });

        if (this.addBankData.bank_name != "微信") {
          this.files.forEach((e) => {
            formData.append("files[]", e.file);
          });
        }

        this.$http
          .post(`/mobile/bankCard`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            if (res.data.code == 0) {
              vm.addBankModal = false;
              vm.getBank();
              Toast.success("成功");
            } else {
              alert(res.data.message);
            }
          });
        done(false);
      } else {
        done();
        return;
      }
    },
    editBankBefore(method, done) {
      if (method == "confirm") {
        this.editBankModal = true;
        let data = JSON.parse(JSON.stringify(this.currentData));
        delete data.enable;
        this.$http
          .put(`/mobile/bankCard/${this.currentData.id}`, {
            ds_limit: data.ds_limit,
            bank_no: data.bank_no,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.bankList.forEach((e, index) => {
                if (e.id == this.currentData.id) {
                  this.bankList[index].ds_limit = this.currentData.ds_limit;
                }
              });
              this.editBankModal = false;
              Toast.success("成功");
            }
          });
        done(false);
      } else {
        done();
        return;
      }
    },
    onClickLeft() {
      this.$router.push(this.$cookie.get("prevPage") || "/");
    },
    scrollPage() {
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.next_page) {
          if (this.wait) return;
          this.wait = true;
          this.currentPage += 1;
          this.getBank();
        }
      }
    },
  },
  created() {
    this.getBank();
    this.$http.get(`/mobile/banks`).then((res) => {
      this.banks = res.data.data;
    });

    // this.getCT = setInterval(() => {
    //   this.getAliCookie();
    // }, 2000);
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    clearInterval(this.getCT);
    window.removeEventListener("scroll", this.scrollPage);
  },
  computed: {
    ...mapState([
      "showOverlay",
      // ...
    ]),
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  margin-top: 0px !important;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tableWrap {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  width: 355px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  .tableContent {
    width: 100%;
    display: flex;
    font-size: 14px;
    align-items: center;
    height: 44px;
    border-bottom: 1px solid #eaeaea;
    justify-content: space-between;
    .tableTitle {
      font-weight: 400;
      white-space: nowrap;
      width: 60px;
      text-align: left;
      margin-right: 10px;
      font-weight: bold;
    }
    .tablesContent {
      color: #999999;
      word-break: break-all;
      display: flex;
      align-items: center;
    }
  }
}

.addAcc {
  display: flex;
  align-items: center;
  // color: #009aaa;
  font-size: 14px;
  .plus {
    color: #009aaa !important;
    font-weight: bold;
    font-size: 14px;
    margin-right: 5px;
    //
  }
}
.editBtn {
  height: 45px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  p {
    width: 49%;
    line-height: 35px;
    font-size: 12px;
    height: 35px;
    background-color: #f1f5fe;
    color: #4065f0;
    border-radius: 100px;
    margin-top: 10px;
    &:last-child {
      background-color: rgb(255, 219, 220);
      color: #f04040;
    }
  }
}

.dialogImg {
  position: absolute;
  width: 109px;
  top: -67px;
  right: 32px;
  z-index: -1;
}
.multiselect {
  width: 95%;
  margin: 0 auto;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
